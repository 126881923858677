/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    base: '/',
    mode: 'history',
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

        {
            path: '/carts',
            component: () => import('./Home.vue'),
            name:'cart'
        },
        {
            path: '/pay',
            component: () => import('./Home.vue'),
            name:'pay'
        },
        {
            path: '/paid/:id',
            component: () => import('./Home.vue'),
            name:'paid'
        },
        {
            path: '/admin',
            component: () => import('./components/admin/Index.vue'),
            name:'admin'
        },
        {
            path: '/admin/food',
            component: () => import('./components/admin/Food/Index.vue'),
            name:'foodadmin'
        },
        {
            path: '/admin/wine',
            component: () => import('./components/admin/Wine/Index.vue'),
            name:'wineadmin'
        },
        {
            path: '/admin/user',
            component: () => import('./components/admin/User/Index.vue'),
            name:'useradmin'
        },
        {
            path: '/admin/payment',
            component: () => import('./components/admin/Payment/Index.vue'),
            name:'paymentadmin'
        },
        {
            path: '/admin/squareup',
            component: () => import('./components/admin/Squareup/Index.vue'),
            name:'squareupadmin'
        },
        {
            path: '',
            component: () => import('./Home.vue'),
            name:'home'
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/error-404'
        }
    ],
})

export default router
